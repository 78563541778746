.five-section {
    &__content {
        margin-top: 160px;
        background: url("./images/fivesection_bg.webp");
        background-repeat: no-repeat;
        background-position: center right;
        background-size: cover;
        background-color: #272726;
        border-radius: 15px;
        @media (max-width: 850px) {
            background: url("./images/tablet-bg.png");
            background-position: bottom center;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: #272726;
        }
        @media (max-width: 440px) {
            margin-top: 120px;
        }
        &-inner {
            padding: 80px 69px 112px;
            @media (max-width: 850px) {
                padding: 40px 40px 320px 40px;
                border-radius: 15px;
            }
            @media (max-width: 440px) {
                background: url("./images/five-bg-mob.png");
                background-position: bottom center;
                background-repeat: no-repeat;
                background-size: contain;
                background-color: #272726;
                padding: 60px 20px 320px 20px;
                border-radius: 15px;
            }
            form {
                display: flex;
                flex-direction: column;
                width: 38%;

                @media (max-width: 850px) {
                    width: 70%;
                }
                @media (max-width: 440px) {
                    width: 100%;
                }
                h4 {
                    font-size: 32px;
                    font-weight: 600;
                    color: white;
                    line-height: 44.8px;
                    @media (max-width: 850px) {
                        font-size: 24px;
                        line-height: 33.6px;
                    }
                    @media (max-width: 440px) {
                        font-size: 24px;
                        text-align: center;
                    }
                }
                p {
                    font-family: "Inter";
                    color: #fdd551;
                    font-weight: 200;
                    font-size: 20px;
                    line-height: 30px;
                    padding-bottom: 30px;
                    @media (max-width: 850px) {
                        font-size: 16px;
                        line-height: 24px;
                    }
                    @media (max-width: 440px) {
                        font-size: 14px;
                        text-align: center;
                        padding-bottom: 20px;
                    }
                }
                input, .react-tel-input .form-control {
                    width: 100%;
                    height: 100%;
                    border-radius: 60px;
                    border: 1px solid rgba(255, 255, 255, 0.2);
                    padding: 12px 25px;
                    background-color: #272726;
                    color: rgba(255, 255, 255, 0.7);
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 27px;
                    &:nth-child(2) {
                        padding: 12px 25px 12px 80px;
                    }
                    @media (max-width: 440px) {
                        font-size: 16px;
                    }
                    &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                        margin-bottom: 10px;
                    }
                    &::-webkit-input-placeholder,
                    &::-moz-placeholder,
                    &:-ms-input-placeholder,
                    &::-ms-input-placeholder,
                    &::placeholder {
                        font-family: "Inter";
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 27px;
                        color: rgba(255, 255, 255, 0.5);
                        @media (max-width: 440px) {
                            font-size: 16px;
                        }
                    }
                    &:active, &:focus {
                        border: 1px solid rgba(255, 255, 255, 0.7);
                    }
                }

                // Красная обводка для инпутов с ошибкой
                .input-error  {
                    border: 1px solid red !important;
					border-radius: 70px;

                }

				

                .react-tel-input .flag-dropdown {
                  margin: 1px;
                    border-radius: 60px 0 0 60px;
					border: none;
                }

                .react-tel-input .form-control:focus {
                    border: 1px solid rgba(255, 255, 255, 0.7);
                }

                .react-tel-input .selected-flag {
                    background-color: #272726;
                    border-radius: 60px 0 0 60px;
                    padding: 0 12px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                }

                // Стрелочка с использованием изображения
                .react-tel-input .arrow {
                    width: 12px;
                    height: 12px;
                    background-image: url("./images/arrow.svg"); // Ваше изображение стрелочки
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-left: 10px; // Отступ от флага
                    transition: transform 0.3s ease; // Плавная анимация поворота
                    top: -3px;
                }

                // Когда меню открыто, поворачиваем стрелку
                .react-tel-input .flag-dropdown.open .arrow {
                    transform: rotate(180deg); // Поворот стрелки вверх
                }

                a {
                    margin-top: 30px;
                    min-width: 233px;
                    @media (max-width: 440px) {
                        font-family: "Manrope";
                        font-size: 16px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

.reset-button-styles {
    all: unset; // Reset all styles
    display: inline-block; // Make it behave like a button
}

.error-message {
    color: red;
    margin-top: 10px;
}

.success-message {
    color: green;
    margin-top: 10px;
}

.react-tel-input {
    margin-bottom: 10px;
    width: 100%; // Устанавливаем ширину поля

    .flag-dropdown.open .selected-flag {
        background-color: #272726;
        color: rgba(255, 255, 255, 0.7);
    }

    .country-list {
        background-color: #272726;
        color: rgba(255, 255, 255, 0.7);

        .country {
            background-color: #272726;
            color: rgba(255, 255, 255, 0.7);

            &:hover, &.highlight {
                background-color: #1e1e1e;
            }
        }
    }

    .selected-flag {
        width: 70px;
        background-color: #272726;
        border-radius: 60px 0 0 60px;
    }

    .form-control {
        background-color: #272726;
        color: rgba(255, 255, 255, 0.7);
        border-radius: 0 60px 60px 0; // Скругление правого угла
        padding-left: 90px; // Отступ для телефона
        height: 48px;
        font-size: 16px;
        line-height: 27px;
        border: 1px solid rgba(255, 255, 255, 0.2);

        &:focus {
            border: 1px solid rgba(255, 255, 255, 0.7);
        }

        &::placeholder {
            color: rgba(255, 255, 255, 0.5);
        }
    }

    .flag-dropdown {
        border-radius: 60px 0 0 60px;
    }

    .flag-container {
        padding-left: 10px; // Регулируем отступы для флага
    }

    .selected-flag .arrow {
        position: relative;
        top: 50%;
        margin-top: -2px;
        left: 20px;
        width: 0;
        height: 0;
        border: none;
        transform: rotate(120deg);
    }

    .selected-flag .arrow.up {
        border: none;
    }

    .flag-dropdown.open .selected-flag {
        border-radius: 60px 0 0 60px;
    }
}
.react-tel-input .flag-dropdown {
	border: none;
}

input:-webkit-autofill, 
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
textarea:-webkit-autofill, 
textarea:-webkit-autofill:hover, 
textarea:-webkit-autofill:focus, 
select:-webkit-autofill, 
select:-webkit-autofill:hover, 
select:-webkit-autofill:focus {
    border: 1px solid rgba(255, 255, 255, 0.7); // Ваш стиль границы
    -webkit-text-fill-color: rgba(255, 255, 255, 0.7); // Цвет текста
    -webkit-box-shadow: 0 0 0px 1000px #272726 inset; // Цвет фона
    transition: background-color 5000s ease-in-out 0s;
}
