* {
    scroll-behavior: smooth;
}

html {
    -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
		background-color: #1A1A19;
}

body {
    -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
    font-family: "Manrope";
    overflow-x: hidden !important;
    font-size: 16px;
	color: rgba($color: #FFFFFF, $alpha: 0.8);
	background-color: #1A1A19;

}

.container {
	max-width: 1220px;
	margin: 0 auto;
}

@media (max-width: 850px) {
	.container {
		width: 100%;
		padding: 0 40px;
	}
}

@media (max-width: 440px) {
	.container {
		width: 100%;
		padding: 0 15px;
	}
}