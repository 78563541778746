.thanks-page {
	padding-top: 160px;
	padding-bottom: 200px;
	
	&__content {
        display: flex;
        // justify-content: space-between;
        gap: 205px;
        @media (max-width: 850px) {
            gap: 50px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        @media (max-width: 440px) {
        }
        &-nav {
            a {
                display: flex;
               gap: 12px;
            }
            &__btn {
                padding: 6px 20px;
                border: none;
                border-radius: 70px;
                background-color: #323231;
               
            }
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Inter";
                font-size: 14px;
                font-weight: 400;
                color: rgba($color: #fff, $alpha: 0.7);
            }
            
            
        }
        &-text {
            display: flex;
            justify-content: center;
            align-items: center;
            &__img {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 21px;
            }
            &__thx {
                max-width: 350px;
                h3 {
                    font-family: "Manrope";
                    font-weight: 600;
                    font-size: 20px;
                    text-align: center;
                    color: white;
                }
                p {
                    font-family: "Inter";
                    font-size: 16px;
                    font-weight: 300;
                    text-align: center;
                    &:nth-child(2) {
                        color: #FDD551;
                        font-style: italic;
                        font-weight: 300;
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }
}
