.promo-section {
	padding-top: 100px;
	padding-bottom: 63px;
	&__content {
		background-color: #fdd551;
		border-radius: 10px;
		padding: 40px 60px;
		display: flex;
        justify-content: space-between;
        color: #1A1A19;
        @media (max-width: 850px) {
            flex-direction: column;
            padding: 15px 30px;
        }
		&-left {
			width: 45%;
			display: flex;
			flex-direction: column;
            @media (max-width: 850px) {
                width: 100%;
                justify-content: center;
                // align-items: center;
            }
            p {
                font-family: "Manrope";
                &:nth-of-type(1) {
                    font-weight: 600;
                    font-size: 48px;
                    line-height: 67.2px;
                    @media (max-width: 440px) {
                    font-size: 36px;
                    }
                }
                &:nth-of-type(2) {
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 44.8px;
                    text-align: center;
                    padding-bottom: 40px;
                    @media (max-width: 440px) {
                    font-size: 20px;
                    }
                    @media (max-width: 440px) {
                    padding-bottom: 20px;
                    }
                }
            }
            button {
                width: fit-content;
                background-color: #1A1A19;
                padding: 16px 32px;
                border: none;
                border-radius: 60px;
                color: white;
                white-space: nowrap;
                @media (max-width: 850px) {
                    margin: 0 auto;
                    margin-bottom: 60px;
                }
                @media (max-width: 440px) {
margin-bottom: 20px;
                }
               a {
                display: flex;
                gap: 25px;
               }
                img {
                    transform: rotate(270deg);
                }

            }
		}
		&-right {
			width: 55%;
			display: flex;
			flex-direction: column;
            gap: 20px;
            @media (max-width: 850px) {
                width: 100%;
            }
            &__item {
                display: flex;
                gap: 20px;
                p {
                    font-family: "Inter";
                    font-size: 16px;
                    font-weight: 500;
                    @media (max-width: 440px) {
                    font-size: 14px;
                    }
                }
            }
		}
	}
}
