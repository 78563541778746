.regBtn {
	position: relative;
	border-radius: 60px;
	background: none;
	border: none;
	padding: 16px 32px;
	overflow: hidden;
	display: flex;
	gap: 25px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	max-width: 237px;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 60px;
		background: linear-gradient(
			231deg,
			#a0752b -75.91%,
			#fdd551 -4.66%,
			#fdd551 4.25%,
			#f8f4ab 23.84%,
			#fdd551 41.65%,
			#fdd551 45.21%,
			#a0752b 87.96%
		);
		z-index: 0;
		transition: background 0.5s ease-in-out, background-position 0.5s ease-in-out;
		background-size: 200% 200%;
		background-position: center;
	}

	&:hover::before {
		background: linear-gradient(
			231deg,
			#ac8034 -75.91%,
			#fdd551 -4.66%,
			#d6ba5f 4.25%,
			#f8f4ab 23.84%,
			#fdd551 41.65%,
			#fdd551 45.21%,
			#a0752b 87.96%
		);
		background-size: 200% 200%;
		background-position: center right;
	}

	span {
		color: black;
		font-family: "Manrope";
		font-size: 20px;
		font-weight: 500;
		line-height: 140%;
		letter-spacing: -2%;
		text-transform: uppercase;
		position: relative;
		z-index: 1;
		text-decoration: none; /* Убираем подчеркивание ссылки */
		white-space: nowrap;
		@media (max-width: 850px) {
			font-size: 16px;
		}
		@media (max-width: 440px) {
			
		}
	}

	img {
		position: relative;
		z-index: 1;
	}
}
