.rate-section__content-h1 {
	padding-bottom: 45px;
	@media (max-width: 440px) {
		padding-bottom: 20px;
		}
	span {
		// display: none;
		color: white;
		font-size: 32px;

		&:nth-child(1) {
			span {
				font-family: "Manrope";
				font-size: 48px;
				padding-right: 12px;
				font-weight: 500;
				@media (max-width: 440px) {
					font-size: 24px;
					
				}
			}
		}
		
		@media (max-width: 850px) {
			
			padding-bottom: 40px;
		}
		@media (max-width: 440px) {
			font-size: 18px;
			
		}
	}
}

.rate-section__content-upheader {
	display: flex;
	justify-content: space-between;
	gap: 22px;
	margin-bottom: 100px;
	@media (max-width: 850px) {
		flex-direction: column;
	}
	&__item {
		position: relative;
		background-color: #272726;
		width: 33%;
		border-radius: 15px;
		@media (max-width: 850px) {
			width: 100%;
		}
		p {
			padding: 25px 39px 24px 98px;
			font-family: "Inter";
			font-size: 16px;
			font-weight: 200;
			color: rgba($color: #fff, $alpha: 0.7);
			@media (max-width: 850px) {
				font-size: 14px;
				padding: 25px 20px 25px 98px;
			}
			@media (max-width: 440px) {
				padding: 25px 20px 25px 98px;
			}
		}
		img {
			position: absolute;
			top: 25%;
			@media (max-width: 850px) {
				top: 10%;
			}
			@media (max-width: 440px) {
				top: 25%;
			}
		}
	}
}

.rate-section__content-header {
	background-color: #272726;
	border-radius: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 440px) {
		justify-content: center;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	

	&__text {
		display: flex;
		align-items: center;
		position: relative;

		&:hover {
			.hover-block {
				opacity: 1;
				visibility: visible;
			}
		}

		p {
			font-weight: 600;
			font-size: 36px;
			line-height: 50.4px;
			color: rgba($color: #fff, $alpha: 0.7);
			padding-left: 37px;

			@media (max-width: 850px) {
				font-size: 32px;
			}

			@media (max-width: 440px) {
				display: none;
				
			}
		}

		.hover-block {
			position: absolute;
			top: 60px;
			left: 6px;
			background-color: #161616;
			padding: 18px 13px;
			border: 1px solid #313131;
			border-radius: 10px;
			color: #fdd551;
			font-family: "Manrope", sans-serif;
			font-size: 16px;
			line-height: 1.4;
			text-align: center;
			width: 100%;
			z-index: 10; /* Убедитесь, что это значение больше, чем у других элементов */
			opacity: 0; /* Начальное состояние: скрыто */
			visibility: hidden; /* Начальное состояние: скрыто */
			transition: opacity 0.3s ease, visibility 0.3s ease; /* Переход для opacity и visibility */

			&:before {
				content: "";
				position: absolute;
				top: -20px; /* Позиционирование стрелки относительно блока */
				left: 13%;
				transform: translateX(-50%);
				border-width: 10px;
				border-style: solid;
				border-color: transparent transparent #161616 transparent; /* Цвет и направление стрелки */
				z-index: -1; /* Убедитесь, что стрелка находится под другими элементами, если нужно */
			}
		}
	}
	&__btns {
		display: flex;
		gap: 13px;
		justify-content: center;
		align-items: center;
		@media (max-width: 440px) {
			gap: 10px;
			align-items: center;
			margin: 0 auto;
		}
		.arrowRate {
			display: flex;
			justify-content: center;
			align-items: center;
			content: url("./arrow-right.png");
			cursor: pointer;
			background: #fdd85a;
			border: none;
			border-radius: 500px;
			width: 24px;
			height: 24px;
			&:nth-child(3) {
				transform: rotate(180deg);
				margin-right: 15px;
			}
		}

		.selected-button {
			background-color: #1a1a19;
			border-radius: 15px;
			border: 1px solid #313131;
			padding: 18px 0px;
			min-width: 280px;
			display: flex;
			flex-direction: column;
			align-items: center;

			p {
				&:nth-child(1) {
					color: #fdd85a;
					font-family: "Inter";
					font-size: 16px;
					font-weight: 500;
					line-height: 24px;
					@media (max-width: 440px) {
						font-size: 10px;
					}
				}
				&:nth-child(2) {
					font-family: "Inter";
					font-size: 36px;
					font-weight: 500;
					line-height: 54px;
					background: rgb(160, 117, 43);
					background: linear-gradient(
						90deg,
						rgb(219, 164, 67) 0%,
						rgba(253, 213, 81, 1) 40%,
						rgba(253, 213, 81, 1) 45%,
						rgba(248, 244, 171, 1) 56%,
						rgba(253, 213, 81, 1) 66%,
						rgba(253, 213, 81, 1) 68%,
						rgb(236, 197, 129) 92%
					);
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					@media (max-width: 440px) {
						font-size: 16px;
					}
				}
			}
		}

		button {
			&.first {
				background-color: #1a1a19;
				border-radius: 15px;
				border: 1px solid #313131;
				padding: 18px 0px;
				min-width: 280px;
				@media (max-width: 440px) {
					min-width: 100px;
					padding: 0 5px;
					min-width: 100px;
					padding: 0 5px;
					min-height: 80px;
				}
				p {
					span {
						font-size: 24px;
					}
					&:nth-child(1) {
						font-family: "Inter";
						font-size: 36px;
						font-weight: 400;
						line-height: 54px;
						background: rgb(160, 117, 43);
						background: linear-gradient(
							90deg,
							rgb(219, 164, 67) 0%,
							rgba(253, 213, 81, 1) 40%,
							rgba(253, 213, 81, 1) 45%,
							rgba(248, 244, 171, 1) 56%,
							rgba(253, 213, 81, 1) 66%,
							rgba(253, 213, 81, 1) 68%,
							rgb(236, 197, 129) 92%
						);
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						@media (max-width: 440px) {
							font-size: 14px;
							line-height: 150%;
						}
						
					}
					&:nth-child(2) {
						
						color: #fdd85a;
						font-family: "Inter";
						font-size: 16px;
						font-weight: 400;
						line-height: 24px;
						@media (max-width: 440px) {
							font-size: 10px;
						}
					}
				}
			}
			&.second {
				background-color: #fdd85a;
				border-radius: 15px;
				border: 1px solid #fdd85a;
				padding: 18px 0px;
				min-width: 280px;
				@media (max-width: 440px) {
					min-width: 100px;
					padding: 0 5px;
					min-height: 80px;
				}
				p {
					span {
						font-size: 24px;
						
					}
					&:nth-child(1) {
						font-family: "Inter";
						font-size: 36px;
						font-weight: 500;
						line-height: 54px;
						color: black;
						@media (max-width: 440px) {
							font-size: 14px;
							line-height: 150%;
						}
					}
					&:nth-child(2) {
						
						color: black;
						font-family: "Inter";
						font-size: 16px;
						font-weight: 500;
						line-height: 24px;
						@media (max-width: 440px) {
							font-size: 10px;
						}
					}
				}
			}
			&.third {
				min-width: 280px;
				background: rgb(187, 172, 123);
				background: linear-gradient(
					23deg,
					rgba(187, 172, 123, 1) 0%,
					rgba(253, 213, 81, 1) 33%,
					rgba(253, 213, 81, 1) 54%,
					rgba(244, 224, 160, 1) 72%,
					rgba(255, 224, 121, 1) 82%,
					rgba(253, 213, 81, 1) 90%,
					rgba(253, 213, 81, 1) 99%
				);
				border-radius: 15px;
				border: 1px solid #313131;
				padding: 18px 0;
				@media (max-width: 440px) {
					min-width: 100px;
					padding: 0 5px;
					min-width: 100px;
					padding: 0 5px;
					min-height: 80px;
				}
				p {
					span {
						font-size: 24px;
						@media (max-width: 440px) {
							font-size: 16px;
							
						}
					}
					&:nth-child(1) {
						font-family: "Inter";
						font-size: 36px;
						font-weight: 500;
						line-height: 54px;
						color: black;
						@media (max-width: 440px) {
							font-size: 14px;
							line-height: 150%;
						}
					}
					&:nth-child(2) {
						color: black;
						font-family: "Inter";
						font-size: 16px;
						font-weight: 500;
						line-height: 24px;
						@media (max-width: 440px) {
							font-size: 10px;
						}
						
					}
				}
			}
		}
	}
}

@media (max-width: 850px) {
	.rate-section__content-header__btns {
		.arrow {
			display: block;
		}

		.selected-button {
			display: flex;
		}

		button.first,
		button.second,
		button.third {
			display: none;
		}
	}
}

@media (max-width: 440px) {
	.rate-section__content-header__btns {
		.arrow {
			display: block;
		}

		.selected-button {
			display: flex;
		}

		button.first,
		button.second,
		button.third {
			display: block;
		}
	}
}
