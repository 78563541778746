.howto-section {
	padding-top: 168px;
	@media (max-width: 440px) {
		padding-top: 150px;
	}
	.howto__content {
		.five-section {
			.container {
				padding: 0;
			}
		}
		&-headertext {
			h2 {
				font-family: "Manrope";
				font-weight: 600;
				font-size: 48px;
				line-height: 140%;
				color: white;
				@media (max-width: 850px) {
					font-size: 40px;
				}
				@media (max-width: 440px) {
					font-size: 36px;
				}
			}
			p {
				color: white;
				font-weight: 500;
				font-size: 32px;
				line-height: 140%;
				padding-left: 190px;
				@media (max-width: 850px) {
					font-size: 24px;
				}
				@media (max-width: 440px) {
					font-size: 20px;
					padding-left: 0px;
				}
			}
		}
		&-blocks {
			padding-top: 80px;
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
            @media (max-width: 850px) {
                flex-direction: column;
            }
            @media (max-width: 440px) {
				padding-top: 40px;
                
            }
			&__item {
				border-radius: 15px;
				width: 49%;
				background-color: #272726;
				padding: 40px 20px 40px 173px;
				display: flex;
                @media (max-width: 850px) {
                    width: 100%;
                    padding: 20px;
				}
				@media (max-width: 440px) {
					padding: 15px;
				}
				&-one {
					width: 50px;
					height: 50px;
					margin-top: 10px;
				}
				&:nth-child(1) {
					background-image: url("./images/1.png");
					background-repeat: no-repeat;
					background-position: bottom left;
				}
				&:nth-child(2) {
					background-image: url("./images/2.png");
					background-repeat: no-repeat;
					background-position: bottom left;
				}
				&:nth-child(3) {
					background-image: url("./images/3.png");
					background-repeat: no-repeat;
					background-position: bottom left;
				}
				&:nth-child(4) {
					background-image: url("./images/4.png");
					background-repeat: no-repeat;
					background-position: bottom left;
				}
				&-two {
					padding-left: 20px;
					h4 {
						color: #fdd85a;
						font-weight: 500;
						font-family: "Inter";
						font-size: 24px;
						padding-bottom: 16px;
                        @media (max-width: 440px) {
                                font-size: 18px;
                        }
					}
					p {
						color: rgba($color: #fff, $alpha: 0.7);
						font-weight: 200;
						font-family: "Inter";
                        @media (max-width: 440px) {
                            font-size: 14px;
                        }
					}
				}
			}
		}
	}
}
