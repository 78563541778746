footer {
	padding-top: 160px;

	@media (max-width: 850px) {
					padding-top: 120px;
	}
	.footer__content {
		padding-bottom: 40px;
		&-items {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@media (max-width: 850px) {
			flex-direction: column;		
			justify-content: center;
			align-items: center;
			}
			&__urls {
				display: flex;
				gap: 135px;
				@media (max-width: 850px) {
					flex-direction: column;		
					justify-content: center;
					align-items: center;
					gap: 40px;
				}
				&-nav {
					nav {
						display: flex;
						gap: 135px;
						@media (max-width: 850px) {
							flex-direction: column;		
							justify-content: center;
							align-items: center;
							gap: 0;
						}
						.footer__content-items__urls-nav__first {
							display: flex;
							flex-direction: column;
							@media (max-width: 850px) {
								flex-direction: column;		
								justify-content: center;
								align-items: center;
								gap: 0;
							}
							a {
								padding-bottom: 12px;
								color: rgba($color: #fff, $alpha: 0.7);
								font-family: "Inter";

								transition: all 0.3s;
								&:hover {
									color: white;
								}
							}
						}
					}
				}
			}
			&__contact {
				display: flex;
				// justify-content: flex-end;
				gap: 20px;
				&-items {
					display: flex;
					justify-content: space-between;
					gap: 134px;
					@media (max-width: 440px) {
						flex-direction: column;
						gap: 15px;
					}
					button {
						background-color: transparent;
						border: none;
						a {
							display: flex;
							gap: 9px;
							color: rgba(255, 255, 255, 0.7);
							font-family: "Inter";
							font-weight: 300;
						}
						
					}
					p {
						color: rgba(255, 255, 255, 0.7);
							font-family: "Inter";
							font-weight: 300;
							@media (max-width: 440px) {
								text-align: center;
							}
					}
					&__left {
						display: flex;
						flex-direction: column;
						gap: 13px;
						&-socials {
							display: flex;
							gap: 15px;
						}
						
					}
					&__right {
						display: flex;
						flex-direction: column;
						gap: 13px;
						&-socials {
							display: flex;
							gap: 15px;
						}
						
					}
				}
				
			}
		}
	}
	.copyright {
		background-color: #272726;
		p {
			color: rgba($color: #fff, $alpha: 0.7);
			font-size: 14px;
			font-family: "Inter";
			text-align: center;
			font-weight: 300;
			padding: 10px 0;
			@media (max-width: 850px) {
				text-align: center;
			}
		}
	}
}
