.third-section {
	&__content {
		// min-height: 100vh;
		padding-top: 197px;
		@media (max-width: 850px) {
			padding-top: 120px;
		}
		@media (max-width: 440px) {
			padding-top: 120px;
		}
		&-header {
			padding-bottom: 60px;
			@media (max-width: 440px) {
				padding-bottom: 40px;
			}
			h2 {
				font-size: 48px;
				font-weight: 500;
				line-height: 140%;
				color: white;
				@media (max-width: 850px) {
					font-size: 40px;
				}
				@media (max-width: 440px) {
					font-size: 36px;
				}
			}
			p {
				font-weight: 500;
				font-size: 32px;
				line-height: 140%;
				color: white;
				padding-left: 88px;
				@media (max-width: 850px) {
					font-size: 24px;
				}
				@media (max-width: 440px) {
					font-size: 24px;
					padding-left: 59px;
				}
			}
		}
		&-blocks {
			&__items {
				display: flex;
				justify-content: space-between;
				gap: 21px;
				@media (max-width: 850px) {
					flex-direction: column;
					justify-content: start;
				}
				&-item {
					padding: 20px 36px 20px 40px;
					display: flex;
					flex-direction: column;
					width: 30%;
					justify-content: space-between;
					background-color: #1a1a19;
					border-radius: 15px;
					border: solid 1px #313131;
					position: relative;
					@media (max-width: 850px) {
						width: 100%;
					}
					@media (max-width: 440px) {
						padding: 40px 20px 20px 20px;
					}
					p {
						&:nth-child(1) {
							color: #fdd85a;
							font-family: "Inter";
							line-height: 150%;
							font-size: 16px;
							@media (max-width: 440px) {
								font-size: 14px;
							}
						}
						&:nth-child(2) {
							background: rgb(160, 117, 43);
							background: linear-gradient(
								90deg,
								rgb(219, 164, 67) 0%,
								rgba(253, 213, 81, 1) 40%,
								rgba(253, 213, 81, 1) 45%,
								rgba(248, 244, 171, 1) 56%,
								rgba(253, 213, 81, 1) 66%,
								rgba(253, 213, 81, 1) 68%,
								rgba(160, 117, 43, 1) 92%
							);
							background-clip: text;
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
							font-family: "Manrope";
							line-height: 100%;
							font-size: 36px;
							font-weight: 500;
							padding-bottom: 34px;
							@media (max-width: 440px) {
								padding-bottom: 20px;
							}
						}
						&:nth-child(3) {
							font-weight: 300;
							font-size: 16px;
							line-height: 150%;
							color: rgba($color: #fff, $alpha: 0.7);
							margin-bottom: 65px;
							@media (max-width: 440px) {
								font-size: 14px;
								margin-bottom: 40px;
							}
						}
					}

					a {
						span {
							text-transform: none !important;
						}
						
					}

					&:nth-child(2) {
						background-color: #272726;
					}
					&:nth-child(3) {
						background-color: #fdd85a;
						border-color: #313131;
						p {
							color: rgba($color: #000000, $alpha: 0.7);
							&:nth-child(2) {
								color: black;
								-webkit-text-fill-color: black;
							}
						}

						a {
							background-color: #1a1a19; /* Основной цвет кнопки */
							border: none; /* Убираем границу, если она есть */
							border-radius: 30px; /* Скругленные углы кнопки */
							cursor: pointer; /* Курсор при наведении */
							transition: background-color 0.3s ease; /* Плавный переход для изменения цвета */
							font-size: 16px; /* Размер шрифта */
							font-family: "Manrope", sans-serif; /* Шрифт кнопки */
							color: white; /* Цвет текста кнопки */
								span {
									color: white; /* Цвет текста кнопки */
								}
							&::before {
								display: none;
							}

							a {
								text-transform: none !important;
								color: inherit; /* Наследуем цвет кнопки */
								font-size: 20px;
								letter-spacing: -2%;
							}

							img {
								content: url("../SecondSection/images/arrow-right-gold.png");
							}

							&:hover {
								background-color: #333; /* Цвет кнопки при наведении */
							}

							&:focus {
								outline: none; /* Убираем обводку при фокусе */
							}
						}
					}
				}
			}
		}
	}
}
.third-section-ai {
	&__content {
		padding-top: 160px;
		@media (max-width: 850px) {
			padding-top: 120px;
		}
		@media (max-width: 440px) {
			padding-top: 120px;
		}
		
		&-header {
			display: flex;
			@media (max-width: 850px) {
				flex-direction: column;
			}
			&-text {
				width: 60%;
				padding-top: 160px;
				@media (max-width: 850px) {
					padding-top: 120px;
					width: 100%;
				}
				@media (max-width: 440px) {
					padding-top: 60px;
				}
				&-head {
					h2 {
						font-size: 48px;
						font-weight: 500;
						line-height: 140%;
						color: white;
						@media (max-width: 850px) {
							font-size: 40px;
						}
						@media (max-width: 440px) {
							font-size: 36px;
						}
					}
					p {
						font-weight: 500;
						font-size: 32px;
						line-height: 140%;
						color: white;
						padding-left: 277px;
						position: relative;
						span {
							position: absolute;
							top: -15px;
							@media (max-width: 850px) {
								top: 0;
							}
						}
						@media (max-width: 850px) {
							font-size: 24px;
							padding-top: 15px;
						}
						@media (max-width: 440px) {
							font-size: 24px;
							padding-left: 59px;
						}
					}
				}
				&-desc {
					padding-top: 38px;
					h3 {
						font-family: "Manrope";
						font-weight: 500;
						font-size: 24px;
						line-height: 140%;
						color: rgba($color: #fff, $alpha: 0.7);
						@media (max-width: 850px) {
							
						}
						@media (max-width: 440px) {
							font-size: 18px;
						}
					}
				}
				&-text {
					padding-top: 38px;
					p {
						font-family: "Inter";
						color: rgba($color: #fff, $alpha: 0.7);
						font-weight: 200;
						font-size: 16px;
						line-height: 150%;
						padding-bottom: 14px;
						@media (max-width: 850px) {
							
						}
						@media (max-width: 440px) {
							font-size: 14px;
						}
					}
				}
			}
			&-img {
				padding-top: 30px;
				width: 40%;
				@media (max-width: 850px) {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
				}
				
			}
		}
	}
}