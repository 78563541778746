header {
	padding: 0px 0 14px 0;
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1000;
	width: 100%;
	transition: background-color 0.3s; // анимация смены цвета фона
	background-color: transparent; // изначально прозрачный фон
	.sale__content {
		background-color: #fdd551;
		padding: 8px 0;
		width: 100%;
		max-height: 45px;

		&__items {
			display: flex;
			align-items: flex-end;
			white-space: nowrap;
			flex-wrap: nowrap;
			gap: 11px;
			.saleFirst {
				font-family: "Manrope";
				color: #1a1a19;
				font-weight: 700;
				font-size: 18px;
				line-height: 25.2px;
			}
			.saleSecond {
				font-family: "Manrope";
				color: #1a1a19;
				font-weight: 600;
				font-size: 12px;
				line-height: 19.8px;
			}
			.noName {
				height: 29px;
				width: 1px;
				background-color: black;
				margin: 0 40px;
			}
		}
	}
	.header__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&-urls {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 40px;
			&__menu {
				nav {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 40px;
					font-family: "Inter";
					a {
						transition: all 0.3s;
						&:hover {
							color: white;
						}
					}
					.active {
						color: #fdd85a;
					}
				}
			}
		}
		&-btns {
			display: flex;
			gap: 12px;
			position: relative;
			&__email {
				min-width: 43px;
				background-color: #fdd85a;
				border: none;
				border-radius: 40px;
				padding: 13px;
				a {
					img {
						min-width: 20px;
					}
				}
			}
			&__telegram {
				min-width: 43px;
				background-color: #fdd85a;
				border: none;
				border-radius: 40px;
				padding: 13px;
				a {
					img {
						min-width: 20px;
					}
				}
			}
			&__phone {
				background-color: #fdd85a;
				border: none;
				border-radius: 40px;
				padding: 12px 15px;
				justify-content: center;
				align-items: center;
				display: flex;
				img {
					margin-right: 6px;
					height: 20px;
					width: 20px;
				}
				span {
					&:nth-child(2) {
						display: flex;
						justify-content: center;
						align-items: center;
						padding-right: 12px;
						color: #1a1a19;
						font-weight: 700;
						font-size: 12px;
					}
					&:nth-child(3) {
						color: white;
						font-size: 16px;
					}
				}
			}
		}
		&-tablet {
			display: none;
			&__burger {
				display: none;
				cursor: pointer;
				width: 30px;
				height: 18px;
				position: relative;
				.bar1,
				.bar2 {
					position: absolute;
					width: 100%;
					height: 3px;
					background-color: #ffffff;
					transition: all 0.3s;
				}
				.bar1 {
					top: 0;
				}
				.bar2 {
					bottom: 0;
				}
				&.open .bar1 {
					transform: translateY(7.5px) rotate(45deg);
				}
				&.open .bar2 {
					transform: translateY(-7.5px) rotate(-45deg);
				}
			}
			&__nav {
				display: flex;
				flex-direction: column;
				align-items: center;
				background-color: #1a1a19;
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				padding: 19px 0;
				min-height: 100vh;
				opacity: 0;
				visibility: hidden;
				transform: translateY(-100%);
				transition: all 0.3s ease-in-out;
				&.open {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
				}
				nav {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 20px;
					a {
						color: rgba($color: #fff, $alpha: 0.7);
						font-size: 24px;
					}
					.active {
						color: #fdd85a;
					}
				}
			}
		}
	}
	&.scrolled {
		background-color: rgba(
			$color: #1a1a19,
			$alpha: 1
		); // полупрозрачный белый фон при скролле
		.header__content {
			&-btns {
				display: flex;
				gap: 12px;
				&__telegram {
					background-color: #fdd85a;
					border: none;
					border-radius: 40px;
					padding: 13px;
					img {
						content: url("./images/telegram-black.svg");
					}
				}
				&__phone {
					background-color: #fdd85a;
					border: none;
					border-radius: 40px;
					padding: 12px 15px;
					a {
						display: flex;
						img {
							margin-right: 6px;
							height: 20px;
							width: 20px;
							content: url("./images/phone-black.svg");
						}
						span {
							&:nth-child(2) {
								display: flex;
								justify-content: center;
								align-items: center;
								padding-right: 20px;
								color: #1a1a19;
								font-weight: 700;
								font-size: 12px;
							}
							&:nth-child(3) {
								color: #1a1a19;
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: 850px) {
		// padding: 20px 0;
		padding: 0 0 20px 0;
		&.scrolled {
			.header__content {
				&-btns {
					display: none;
				}
				&-tablet {
					&__btns {
						&__telegram {
							background-color: #fdd85a;
							border: none;
							border-radius: 40px;
							padding: none;
							img {
								content: url("./images/telegram-black.svg");
							}
						}
						&__phone {
							background-color: #fdd85a;
							border: none;
							border-radius: 40px;
							padding: 12px 15px;
							a {
								display: flex;
								img {
									content: url("./images/phone-black.svg");
								}
								span {
									&:nth-child(2) {
										display: flex;
										justify-content: center;
										align-items: center;
										padding-right: 20px;
										color: #1a1a19;
										font-weight: 700;
										font-size: 12px;
									}
									&:nth-child(3) {
										color: #1a1a19;
										font-size: 16px;
									}
								}
							}
						}
					}
				}
			}
		}
		.header__content {
			&-urls {
				display: none;
			}
			&-btns {
				display: none;
			}
			&-tablet {
				display: flex;
				justify-content: space-between;
				width: 100%;
				padding-top: 15px;
				@media (max-width: 440px) {
				}
				&__logo {
					display: flex;
					align-items: center;
					gap: 40px;
				}
				&__btns {
					&-mob {
						display: none;
					}
					@media (max-width: 440px) {
						display: none;
					}
					display: flex;
					gap: 12px;
					justify-content: center;
					align-items: center;
					gap: 12px;
					&__email {
						min-width: 43px;
						background-color: #fdd85a;
						border: none;
						border-radius: 40px;
						padding: 13px;
						a {
							img {
								min-width: 20px;
							}
						}
					}
					&__telegram {
						min-width: 43px;
						background-color: #fdd85a;
						border: none;
						border-radius: 40px;
						padding: 13px;
						a {
							img {
								min-width: 20px;
							}
						}
					}
					&__phone {
						background-color: #fdd85a;
						border: none;
						border-radius: 40px;
						padding: 12px 15px;
						justify-content: center;
						align-items: center;
						display: flex;
						img {
							margin-right: 6px;
							height: 20px;
							width: 20px;
						}
						span {
							&:nth-child(2) {
								display: flex;
								justify-content: center;
								align-items: center;
								padding-right: 12px;
								color: #1a1a19;
								font-weight: 700;
								font-size: 12px;
							}
							&:nth-child(3) {
								color: white;
								font-size: 16px;
							}
						}
					}
				}
				&__burger {
					display: block;
					margin-top: 25px;
					@media (max-width: 440px) {
						display: none;
					}
				}
			}
		}
	}

	.header__content {
		display: flex;
		gap: 60px;

		@media (max-width: 440px) {
			gap: 40px;
		}
	}
	@media (max-width: 440px) {
		background-color: #1a1a19;
		padding: 0 0 12px 0;
		.header__content-tablet__btns-mob {
			display: block;
		}
		.header__content {
			&-tablet {
				&__logo {
					.language-switcher {
						display: none;
					}
				}
				&__btns {
					&-mob {
						&__top {
							display: flex;
							gap: 12px;
							&__email {
								min-width: 43px;
								background-color: transparent;
								border: none;
								border-radius: 40px;
								padding: 13px;
								a {
									display: flex;
									align-items: center;
									img {
										min-width: 20px;
									}
								}
							}
							&__telegram {
								min-width: 43px;
								background-color: transparent;
								border: none;
								border-radius: 40px;
								padding: 13px;
								a {
									img {
										min-width: 20px;
									}
								}
							}
							&__phone {
								background-color: transparent;
								border: none;
								border-radius: 40px;
								padding: 12px 15px;
								justify-content: center;
								align-items: center;
								display: flex;
								img {
									margin-right: 6px;
									height: 20px;
									width: 20px;
								}
								span {
									&:nth-child(2) {
										display: flex;
										justify-content: center;
										align-items: center;
										padding-right: 12px;
										color: #fdd85a;
										font-weight: 700;
										font-size: 12px;
									}
									&:nth-child(3) {
										color: white;
										font-size: 16px;
									}
								}
							}
						}
						&__bottom {
							display: flex;
							justify-content: flex-end;
							align-items: center;
							gap: 30px;
							padding-right: 15px;
							&__telegram {
								background-color: #1a1a19;
								border: none;
								border-radius: 40px;
								padding: 11px;
								width: 42px;
								height: 42px;
								a {
									img {
										width: 100%;
									}
								}
							}
							&__burger {
								display: block;
								cursor: pointer;
								width: 30px;
								height: 18px;
								position: relative;
								.bar1,
								.bar2 {
									position: absolute;
									width: 100%;
									height: 3px;
									background-color: #ffffff;
									transition: all 0.3s;
								}
								.bar1 {
									top: 0;
								}
								.bar2 {
									bottom: 0;
								}
								&.open .bar1 {
									transform: translateY(7.5px) rotate(45deg);
								}
								&.open .bar2 {
									transform: translateY(-7.5px) rotate(-45deg);
								}
							}
						}
					}
				}
			}
		}
	}
}
.dropdown {
	position: absolute;
	top: 100%;
	right: 0px;
	background-color: #1b1b1a;
	border: 1px solid #323231;
	// padding: 10px;
	width: 192px;
	z-index: 1000;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	ul {
		list-style: none;
		padding: 13px 8px;
		margin: 0;

		li {
			&:last-child {
				margin-bottom: 0;
			}

			a {
				display: flex;
				color: rgba(255, 255, 255, 0.7);
				text-decoration: none;
				font-family: "Inter";
				font-weight: 300;
				gap: 9px;
				flex-wrap: nowrap;
				word-wrap: nowrap;
				white-space: nowrap;
			}
		}
	}
}
.dropdownTablet {
	position: absolute;
	top: 80%;
	right: 80px;
	background-color: #1b1b1a;
	border: 1px solid #323231;
	// padding: 10px;
	width: 192px;
	z-index: 1000;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	ul {
		list-style: none;
		padding: 13px 8px;
		margin: 0;

		li {
			&:last-child {
				margin-bottom: 0;
			}

			a {
				display: flex;
				color: rgba(255, 255, 255, 0.7);
				text-decoration: none;
				font-family: "Inter";
				font-weight: 300;
				gap: 9px;
				flex-wrap: nowrap;
				word-wrap: nowrap;
				white-space: nowrap;
			}
		}
	}
}
.dropdownMob {
	position: absolute;
	top: 70%;
	right: 0px;
	background-color: #1b1b1a;
	border: 1px solid #323231;
	// padding: 10px;
	width: 192px;
	z-index: 1000;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	ul {
		list-style: none;
		padding: 13px 8px;
		margin: 0;

		li {
			&:last-child {
				margin-bottom: 0;
			}

			a {
				display: flex;
				color: rgba(255, 255, 255, 0.7);
				text-decoration: none;
				font-family: "Inter";
				font-weight: 300;
				gap: 9px;
				flex-wrap: nowrap;
				word-wrap: nowrap;
				white-space: nowrap;
			}
		}
	}
}
/* Скрываем все выпадающие меню по умолчанию */

.header__burger-775,
.header__burger-440 {
	display: none; // по умолчанию скрываем бургер-меню
	.burger-icon {
		position: relative;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 30px;
		height: 20px;
		z-index: 999;
		.bar1,
		.bar2 {
			height: 3px;
			width: 100%;
			background-color: #fff;
			transition: transform 0.3s ease;
		}
		&.open .bar1 {
			transform: rotate(45deg) translate(6px, 6px);
		}
		&.open .bar2 {
			transform: rotate(-45deg) translate(6px, -6px);
		}
	}
	.burger-menu-775,
	.burger-menu-440 {
		position: fixed; /* Закрепляем меню на экране */
		top: 153px;
		right: 0;
		background-color: #1a1a19;
		width: 100%;
		height: 100vh; /* Занимаем всю высоту экрана */
		display: flex;
		flex-direction: column;
		align-items: center;

		transform: translateX(100%); /* Прячем меню за экраном */
		transition: transform 0.3s ease-in-out; /* Анимация плавного выезда */

		nav {
			padding-top: 60px;
			display: flex;
			flex-direction: column;
			gap: 20px;
			justify-content: center;
			align-items: center;

			a {
				color: white;
				font-size: 18px;
				text-decoration: none;
			}
		}

		&.open {
			transform: translateX(0); /* Показываем меню, когда оно открыто */
		}
	}
}
.burger-menu-440 {
	top: 145px !important;
}

@media (max-width: 850px) {
	.header__burger-775 {
		display: block; // Показываем бургер на экранах до 850px
	}
}

@media (max-width: 440px) {
	.header__burger-440 {
		display: block; // Показываем бургер на экранах до 440px
	}
}

@media (max-width: 850px) {
	.header__content-urls,
	.header__content-btns {
		display: none; // Скрываем десктопные элементы
	}
}

@media (max-width: 440px) {
	.header__content-urls,
	.header__content-btns,
	.header__burger-775 {
		display: none; // Скрываем также бургер для 850px
	}
}

body {
	&.no-scroll {
		overflow: hidden; /* Отключаем прокрутку */
	}
}
