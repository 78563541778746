.language-switcher {
	position: relative;
	display: inline-block;
    font-weight: 400;
}

.language-switcher-btn {
	cursor: pointer;
	border-radius: 4px;
	color: rgba($color: #FFFFFF, $alpha: 0.8);;
	display: flex;
	align-items: center;
	transition: background-color 0.3s ease;
}

.language-switcher-btn:hover {
	// background-color: #e0e0e0;
}

.arrow {
	margin-left: 8px;
	border: solid rgba($color: #FFFFFF, $alpha: 0.8);
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(45deg);
	transition: transform 0.2s ease;
}

.arrow.up {
	transform: rotate(-135deg);
}

.language-switcher-menu {
	position: absolute;
	top: calc(100% + 8px);
	left: -5px;
	width: 45px;
	background-color: #1A1A19;
	border-radius: 4px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	transition: opacity 0.2s ease, max-height 0.2s ease;
	opacity: 0;
	max-height: 0;
	button {
		color: white;
		&:hover {
			color: black;
		}
	}
}

.language-switcher-menu.entering,
.language-switcher-menu.entered {
	opacity: 1;
	max-height: 200px;
}

.language-switcher-menu button {
	display: block;
	width: 100%;
	padding: 8px 12px;
	border: none;
	background-color: transparent;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.language-switcher-menu button:hover {
	background-color: #e0e0e0;
}
