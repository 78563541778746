.first-section {
	background-color: #272726;
	&__content {
		min-height: 100vh;
		padding-top: 178px;
		background-image: url("../FirstSection/images/firstsection__bg.png");
		background-repeat: no-repeat;
		background-position: right bottom;
		@media (max-width: 850px) {
			padding-top: 158px;
		}
		@media (max-width: 440px) {
			background-size: contain;
			background-position: bottom bottom;
			padding-top: 150px;
			padding-bottom: 380px;
		}
		&-header {
			h1 {
				color: white;
				font-family: "Manrope";
				font-weight: 600;
				font-size: 40px;
				line-height: 140%;
				@media (max-width: 850px) {
					font-size: 36px;
				}
				@media (max-width: 440px) {
					font-size: 32px;
				}
			}
			h2 {
				color: #fdd85a;
				font-family: "Manrope";
				font-weight: 600;
				font-size: 48px;
				line-height: 140%;
				@media (max-width: 850px) {
					font-size: 40px;
				}
				@media (max-width: 440px) {
					font-size: 32px;
				}
			}
			&__sub {
				padding-top: 40px;
				display: flex;
				flex-direction: column;
				gap: 15px;
				@media (max-width: 850px) {
					padding-top: 20px;
				}
				img {
					margin-right: 12px;
				}
				p {
					font-family: "Manrope";
					color: rgba($color: #fff, $alpha: 0.7);
					font-size: 24px;
					font-weight: 300;
					width: 57%;
					line-height: 140%;
                   margin-bottom: 16px;
					@media (max-width: 850px) {
						font-size: 20px;
					}
					@media (max-width: 440px) {
						font-size: 16px;
						width: 100%;
					}
                    
				}
                span {
                    border-radius: 20px;
                    padding: 9px 24px 9px 13px;
                    background-color:#1A1A19;
                    font-family: "Inter";
                    font-style: italic;
                    color: #FDD551;
                    font-size: 19px;
					display: flex;
					width: fit-content;
					span {
						padding: 0;
						margin-left: 6px;
						font-weight: 700;
						font-size: 20px;
					}
					@media (max-width: 850px) {
						// display: block;
						font-size: 10px;
						justify-content: center;
						align-items: center;
					}
                }
			}
		}
		&-btn {
			padding-top: 60px;
			display: flex;
			gap: 19px;
			button {
				&.servicesBtn {
					background-color: #272726;
					border: 1px solid #fdd85a;
					border-radius: 60px;
                    transition: all .3s ease;
					a {
						color: #fdd85a;
						padding: 16px 32px;
						font-family: "Manrope";
						font-size: 20px;
						font-weight: 400;
						line-height: 28px;
						letter-spacing: -2%;
						@media (max-width: 850px) {
							font-size: 16px;
						}
					
					}
                    &:hover {
                        background-color: #1A1A19;
                        border-color: #272726;
                    }
				}
			}
			@media (max-width: 440px) {
				button {
					a {
						font-size: 16px;
					}
				}
			}
		}
	}
}
