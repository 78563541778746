.four-section {
	&__content {
		padding-top: 160px;
		@media (max-width: 850px) {
			padding-top: 120px;
		}
		
		&-header {
			padding-bottom: 60px;
			@media (max-width: 440px) {
				padding-bottom: 40px;
			}
			h2 {
				font-size: 48px;
				font-weight: 500;
				line-height: 140%;
				color: white;
				@media (max-width: 850px) {
					font-size: 40px;
				}
				@media (max-width: 440px) {
					font-size: 36px;
				}
			}
			p {
				font-weight: medium;
				font-size: 32px;
				line-height: 140%;
				color: white;
				width: 48%;
				@media (max-width: 850px) {
					font-size: 24px;
				}
				@media (max-width: 440px) {
					width: 100%;
				}
			}
		}
		&-blocks {
			&__items {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				gap: 20px;
				@media (max-width: 850px) {
					flex-direction: column;
				}
				&-item {
					width: 49%;
					background-color: #272726;
					padding: 20px 43px 37px 40px;
					border-radius: 15px;
					@media (max-width: 850px) {
						width: 100%;
					}
					@media (max-width: 440px) {
						padding: 20px 20px 36px 20px;
					}
					p {
						&:nth-child(2) {
							color: #fdd85a;
							font-family: "Inter";
							font-size: 20px;
							font-weight: medium;
							padding: 20px 0 12px;
							@media (max-width: 440px) {
								font-size: 16px;
								padding: 30px 0 12px;
							}
						}
						&:nth-child(3) {
							color: rgba($color: #fff, $alpha: 0.7);
							font-family: "Inter";
							font-size: 16px;
							font-weight: 200;
							@media (max-width: 440px) {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}
}
