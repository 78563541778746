.rate-section {
	padding-top: 160px;
	@media (max-width: 440px) {
		padding-top: 150px;
		}
}

.hidden {
	display: none;
}

@media (max-width: 850px) {
	.rate-section__content table td {
		display: none;
	}

	.rate-section__content table td:first-child,
	.rate-section__content table td.selected {
		display: table-cell;
	}
}

@media (max-width: 440px) {
	.rate-section__content table td {
		display: none;
	}

	.rate-section__content table td:first-child,
	.rate-section__content table td.selected {
		display: table-cell;
	}
}


