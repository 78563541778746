.why-section {
	padding-top: 168px;
	@media (max-width: 440px) {
		padding-top: 150px;
	}
	.why__content {
		&-header {
			h2 {
				font-size: 32px;
				color: #fff;
				font-weight: 500;
				@media (max-width: 850px) {
					font-size: 30px;
				}
				@media (max-width: 440px) {
					font-size: 26px;
				}
			}
			p {
				font-size: 48px;
				font-weight: 500;
				color: #fff;
				padding-left: 60px;
				@media (max-width: 850px) {
					font-size: 40px;
					padding-left: 0;
				}
				@media (max-width: 440px) {
					font-size: 32px;
				}
			}
		}
		&-firstblocks {
			padding-top: 60px;
			&__items {
				display: flex;
				flex-wrap: wrap;
				gap: 20px;
				@media (max-width: 850px) {
					flex-direction: column;
				}
				&-item {
					width: 49%;
					background-color: #313131;
					border-radius: 15px;
					padding: 60px 40px;
					display: flex;
					gap: 60px;
					justify-content: center;
					align-items: center;

					@media (max-width: 850px) {
						width: 100%;
						align-items: flex-start;
                        flex-direction: column;
					}
					@media (max-width: 440px) {
						gap: 15px;
						padding: 20px;
					}
					&-img {
						box-sizing: border-box;
						width: 80px;
						height: 80px;
						flex-shrink: 0;
						@media (max-width: 850px) {
							min-width: 48px;
							min-height: 48px;
						}
						img {
							width: 100%;
							height: 100%;
							min-width: 80px;
							min-height: 80px;
							object-fit: contain;
							@media (max-width: 850px) {
								min-width: 48px;
								min-height: 48px;
							}
						}
					}
					&-text {
						h4 {
							color: #fdd85a;
							font-size: 20px;
							font-family: "Inter";
							@media (max-width: 850px) {
								font-size: 16px;
							}
						}
						p {
							padding-top: 12px;
							color: rgba(#fff, 0.7);
							font-family: "Inter";
							font-weight: 300;
							@media (max-width: 850px) {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
		&__secondHeader {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 160px;
			width: 100%;
			gap: 120px;
			@media (max-width: 850px) {
				flex-direction: column;
			}
			@media (max-width: 440px) {
				gap: 60px;
				padding-top: 120px;
			}
			&-text {
				width: 60%;
				@media (max-width: 850px) {
					width: 100%;
				}
				h2 {
					font-size: 32px;
					color: #fff;
					font-weight: 500;
					@media (max-width: 440px) {
						font-size: 28px;
					}
				}
				h3 {
					font-size: 48px;
					font-weight: 500;
					color: #fff;
					padding-left: 60px;
					@media (max-width: 440px) {
						font-size: 32px;
						padding-left: 0;
					}
				}
				p {
					padding-top: 40px;
					font-size: 16px;
					font-weight: 300;
					color: rgba($color: #fff, $alpha: 0.7);
				}
			}
			&-img {
				width: 40%;
				@media (max-width: 850px) {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
				}
			}
		}
		&__secondblocks {
			padding-top: 160px;
			@media (max-width: 440px) {
				padding-top: 120px;
			}
			h2 {
				font-size: 32px;
				color: #fff;
				font-weight: 500;
				width: 40%;
				@media (max-width: 850px) {
					width: 90%;
				}
				@media (max-width: 440px) {
					font-size: 28px;
					width: 100%;
				}
			}
			&-items {
				display: flex;
				flex-wrap: wrap;
				padding-top: 60px;
				@media (max-width: 850px) {
					flex-direction: column;
				}
				@media (max-width: 440px) {
					padding-top: 40px;
				}
				&-item {
					display: flex;
					width: 50%;
					padding-bottom: 40px;
					gap: 20px;
					@media (max-width: 850px) {
						width: 100%;
					}
					@media (max-width: 440px) {
						padding-bottom: 20px;
					}
					&-img {
						display: flex;
						align-items: flex-start;
						img {
							min-width: 34px;
							min-height: 30px;
							@media (max-width: 850px) {
								min-width: 26px;
							}
						}
					}
					&-text {
						h5 {
							color: #fdd85a;
							font-family: "Inter";
							font-size: 20px;
							line-height: 150%;
							font-weight: normal;
							margin: 0;
							@media (max-width: 850px) {
								font-size: 18px;
							}
						}
						p {
							padding-top: 10px;
							font-family: "Inter";
							color: rgba($color: #fff, $alpha: 0.7);
							font-weight: 300;
							@media (max-width: 850px) {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
		&__slider {
			padding-top: 160px;
            @media (max-width: 850px) {
                
            }
			@media (max-width: 440px) {
				padding-top: 120px;
			}
			h2 {
				font-size: 32px;
				color: #fff;
				font-weight: 500;
				width: 40%;
				@media (max-width: 850px) {
					width: 100%;
					// text-align: center;
                    font-size: 28px;
				}
			}
			&-inner {
				padding-top: 60px;
				display: flex;
				justify-content: space-between;
				gap: 123px;
				position: relative;
                @media (max-width: 850px) {
                    flex-direction: column;
                    gap: 60px;
                }
                @media (max-width: 440px) {
                    
                }
				&-img {
					width: 50%;
                    @media (max-width: 850px) {
                        width: 100%;
                    }
                    @media (max-width: 440px) {
                        
                    }
				}
				&-text {
					width: 50%;
					position: relative;
                    @media (max-width: 850px) {
                        width: 100%;
                    }
                    @media (max-width: 440px) {
                        
                    }
					h2 {
						width: 60%;
						color: #fdd85a;
						font-family: "Inter";
						font-size: 24px;
                        @media (max-width: 850px) {
                           width: 100%;
                        }
                        @media (max-width: 440px) {
                            font-size: 18px;
                            
                        }
					}
                    p {
                        padding-top: 22px;
                        font-family: "Inter";
                        font-weight: 300;
                        font-size: 16px;
                        width: 80%;
                        @media (max-width: 850px) {
                            width: 100%;
                         }
                         @media (max-width: 440px) {
                             font-size: 14px;
                         }
                    }
					.swiper-button-prev,
					.swiper-button-next {
						position: absolute;
						top: 150%;
						background: none;
						border: none;
						padding: 16px 32px;
						overflow: hidden;
						display: flex;
						gap: 25px;
						justify-content: center;
						align-items: center;
						cursor: pointer;
					}
					.swiper-button-prev {
                        width: 88px;
                        height: 56px;
                        @media (max-width: 850px) {
                            
                         }
                         @media (max-width: 440px) {
                             width: 63px;
                             height: 40px;
                         }
						left: 0px;
                        background-color: #fdd85a;
                        border: 1px solid #fdd85a;
							border-radius: 70px;
                        &::after {
                            content: url("./images/arrow-right.svg");
                            font-size: 0;
                            transform: rotate(180deg);
                        }
						&:disabled {
							background-color: transparent;
							border: 1px solid #fdd85a;
							border-radius: 70px;
							&::after {
								content: url("./images/prev-disabled.svg");
								font-size: 0;
                                transform: rotate(0deg);
							}
						}
					}
					.swiper-button-next {
						right: 320px;
                        @media (max-width: 850px) {
                            right: 0;
                         }
                         @media (max-width: 440px) {
                             
                         }
                        width: 88px;
                        height: 56px;
                        @media (max-width: 850px) {
                            
                        }
                        @media (max-width: 440px) {
                            width: 63px;
                            height: 40px;
                        }
						background-color: #fdd85a;
                        border: 1px solid #fdd85a;
							border-radius: 70px;
                        &::after {
                            content: url("./images/arrow-right.svg");
                            font-size: 0;
                            transform: rotate(0deg);
                        }
						&:disabled {
							background-color: transparent;
							border: 1px solid #fdd85a;
							border-radius: 70px;
							&::after {
								content: url("./images/prev-disabled.svg");
								font-size: 0;
                                transform: rotate(180deg);
							}
						}
					}
				}
			}
			.slide-indicator {
				position: absolute;
				bottom: -26px;
				left: 62%;
				transform: translateX(-50%);
				font-size: 18px;
				color: #424242;
                font-family: "Inter";
                font-size: 24px;
                @media (max-width: 850px) {
                    left: 50%;
                    bottom: -64px
                 }
                 @media (max-width: 440px) {
                    font-size: 16px;
                    bottom: -65px
                 }
			}
		}
	}
}
