.second-section {
	&__content {
		padding-top: 127px;
		@media (max-width: 440px) {
			padding-top: 120px;
		}
		&-header {
			h2 {
				font-family: "Manrope";
				font-weight: 600;
				font-size: 48px;
				line-height: 140%;
				color: white;
				@media (max-width: 850px) {
					font-size: 40px;
				}
				@media (max-width: 440px) {
					font-size: 36px;
				}
			}
			p {
				color: white;
				font-weight: 500;
				font-size: 32px;
				line-height: 140%;
				padding-left: 190px;
				@media (max-width: 850px) {
					font-size: 24px;
				}
				@media (max-width: 440px) {
					font-size: 20px;	
					padding-left: 103px;
				}
			}
		}
		&-tabs {
			margin-top: 60px;
			display: flex;
			justify-content: space-between;
			gap: 100px;
			@media (max-width: 850px) {
				gap: 0;
				justify-content: center;
				flex-direction: column;
			}
			@media (max-width: 440px) {
				margin-top: 40px;		
			}
			&__items {
				width: 40%;
				@media (max-width: 850px) {
					width: 100%;
				}
				&-item {
					display: flex;
					justify-content: left;
					align-items: center;
					gap: 44px;
					padding-bottom: 12px;
					@media (max-width: 850px) {
						gap: 40px;
					}
					@media (max-width: 850px) {
						flex-direction: column;
					}
					span {
						font-size: 20px;
						@media (max-width: 850px) {
							&:nth-child(1) {
								display: none;
							}
						}
						&.active {
							color: #fdd85a;
						}
					}

					button {
						display: flex;
						padding: 16px 32px;
						justify-content: space-between;
						border-radius: 70px;
						border: 1px solid #313131;
						min-width: 349px;
						background-color: #1a1a19;
						transition: all 0.3s ease-in-out;
						@media (max-width: 850px) {
							width: 100%;
						}
						&:hover {
							border: 1px solid #fff;
						}
						p {
							color: rgba($color: #ffffff, $alpha: 0.7);
						}
						&.active {
							border: 1px solid #1a1a19;
							background-color: #272726;
							img {
								transform: rotate(270deg);
							}
							@media (max-width: 850px) {
								img {
									transform: rotate(0deg);
								}
							}

							&:hover {
								border: 1px solid #fff;
							}
							p {
								color: #fdd85a;
							}
						}
					}
				}
			}
			&__content-desktop {
				width: 60%;
				@media (max-width: 850px) {
					display: none;
				}
			}
			&__content-mobile {
				display: none;
				@media (max-width: 850px) {
					display: block;
					width: 100%;
					h4 {
						font-size: 48px;
						@media (max-width: 850px) {
							font-size: 40px;
						}
						line-height: 150%;
						font-weight: 500;
						padding-bottom: 33px;
						@media (max-width: 850px) {
							padding-bottom: 39px;
						}
						@media (max-width: 440px) {
							padding-bottom: 20px;
						}
						background: rgb(160, 117, 43);
						background: linear-gradient(
							90deg,
							rgb(219, 164, 67) 0%,
							rgba(253, 213, 81, 1) 40%,
							rgba(253, 213, 81, 1) 45%,
							rgba(248, 244, 171, 1) 56%,
							rgba(253, 213, 81, 1) 66%,
							rgba(253, 213, 81, 1) 68%,
							rgba(160, 117, 43, 1) 92%
						);
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
					p {
						display: flex;
						justify-content: left;
						align-items: center;
						padding-bottom: 20px;
						text-align: left;

						@media (max-width: 850px) {
							
							strong {
								font-weight: 500;
								font-size: 14px;
							}
							span {
								font-size: 14px;
							}
						}
						@media (max-width: 440px) {
							align-items: flex-start;
						}
						img {
							margin-right: 28px;
							width: 26px;
						}
						span {
							strong {
								color: white;
							}
						}
					}
					
				}
			}
			&__content {
				h4 {
					font-size: 48px;
					@media (max-width: 440px) {
						font-size: 36px;
					}
					line-height: 150%;

					font-weight: 500;
					padding-bottom: 20px;
					background: rgb(160, 117, 43);
					background: linear-gradient(
						90deg,
						rgb(219, 164, 67) 0%,
						rgba(253, 213, 81, 1) 40%,
						rgba(253, 213, 81, 1) 45%,
						rgba(248, 244, 171, 1) 56%,
						rgba(253, 213, 81, 1) 66%,
						rgba(253, 213, 81, 1) 68%,
						rgba(160, 117, 43, 1) 92%
					);
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
				h2 {
					color: rgba($color: #ffffff, $alpha: 0.7);
					font-family: "Inter";
					font-weight: 200;
					font-style: italic;
					font-size: 20px;
					padding-bottom: 41px;
				}
				p {
					display: flex;
					justify-content: left;
					align-items: center;
					padding-bottom: 20px;

					img {
						margin-right: 20px;
					}
					span {
						strong {
							color: white;
						}
					}
				}
			}
		}
	}

	// Styles for mobile version
	@media (max-width: 850px) {
		.second-section__content-tabs__content-desktop {
			display: none; // Hide the desktop content by default
		}

		.second-section__content-tabs__items-item {
			&.active .second-section__content-tabs__content-mobile {
				display: block; // Show content only for active tab
			}
		}
	}
}
