table {
	margin-top: 40px;

	@media (max-width: 440px) {
		p,
		span {
			font-size: 12px;
		}
	}
}

tbody {
	position: relative;

	tr {
		&:nth-child(odd) {
			background-color: #272726; // Цвет фона для нечётных строк
		}

		&:nth-child(even) {
			background-color: #1a1a19; // Цвет фона для чётных строк
		}

		td {
			overflow: hidden; // Добавляет поддержку для закругления углов
			padding: 6px;
			text-align: center;

			&:nth-child(1) {
				border-top-left-radius: 15px;
				border-bottom-left-radius: 15px;
				width: 28%;
				text-align: left;
				padding-left: 39px;

				@media (max-width: 850px) {
					width: 17%;
				}

				@media (max-width: 441px) {
					padding-left: 14px;
				}
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				width: 22.97%;
			}

			img {
				display: block;
				margin: 0 auto;
			}
		}

		&.second-table {
			background-color: #fdd85a;
			border-radius: 15px;
			width: 100%;

			td {
				width: 10%;
				padding: 6px 40px;
				text-align: center;

				img {
					display: block;
					margin: 0 auto;
				}

				p {
					font-family: "Inter";
					color: #1a1a19;
					font-style: italic;
					font-weight: 700;
					text-align: left;
				}
			}
		}
	}
}

.rounded-thead {
	background-color: #fdd85a;

	tr {
		border-radius: 15px;
	}

	td {
		border-radius: 15px;
		overflow: hidden;
	}

	p {
		margin: 0;
		padding: 6px 40px;
		font-style: italic;
		color: #1a1a19;
		font-family: "Inter";
		font-size: 16px;
		font-weight: 600;
	}
}

.hidden {
	display: none;
}

.hover-block {
	display: none; // Скрываем блок по умолчанию
	position: absolute;
	left: 0;
	min-width: 200px;
	max-width: 280px;
	color: #000; // Цвет текста блока
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	z-index: 10;
	left: 6px;
	background-color: #161616;
	padding: 18px 13px;
	border: 1px solid #313131;
	border-radius: 10px;
	color: #fdd551;
	font-family: "Inter", sans-serif;
	font-size: 12px;
	line-height: 1.4;
	text-align: left;
	font-weight: 300;
	z-index: 10; /* Убедитесь, что это значение больше, чем у других элементов */

	&:before {
		content: "";
		position: absolute;
		top: -20px; /* Позиционирование стрелки относительно блока */
		left: 50px;
		transform: translateX(-50%);
		border-width: 10px;
		border-style: solid;
		border-color: transparent transparent #161616 transparent; /* Цвет и направление стрелки */
		z-index: -1; /* Убедитесь, что стрелка находится под другими элементами, если нужно */
	}
}

.table-container {
	tr {
		&:hover {
			.hover-block {
				display: block; // Показываем блок при наведении на строку
			}
		}

		@media (max-width: 850px) {
			td {
				display: none;

				&:first-child,
				&.selected {
					display: table-cell;
				}
			}
		}

		@media (max-width: 440px) {
			td {
				display: none;

				&:first-child,
				&.selected {
					display: table-cell;
				}
			}
		}
	}
}

.additional-table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	margin-top: 60px;

	thead {
		background-color: #fdd85a;
		overflow: hidden;
		// border-radius: 15px;
		th {
			padding: 6px;
			text-align: center;
			font-style: italic;
			font-weight: 700;
			color: #1a1a19;
			overflow: hidden;
			font-family: "Inter";
			font-weight: 500;
			font-style: italic;
			font-size: 16px;
			line-height: 24px;
			@media (max-width: 850px) {
				font-size: 14px;
			}
			@media (max-width: 440px) {
			}
			&:nth-child(1) {
				text-align: left;
				padding-left: 40px;
				border-bottom-left-radius: 15px;
				border-top-left-radius: 15px;
				@media (max-width: 850px) {
					padding-left: 10px;
				}
				@media (max-width: 440px) {
				}
			}
			&:nth-child(3) {
				border-bottom-right-radius: 15px;
				border-top-right-radius: 15px;
			}
		}
	}

	tbody {
		tr {
			background-color: #1a1a19;

			&:nth-child(odd) {
				background-color: #272726;
			}

			td {
				padding: 12px;
				vertical-align: top;
				text-align: start;
				
				&:nth-child(1) {
					width: 35%;
					padding-right: 80px;
					padding-left: 40px;
					@media (max-width: 850px) {
						padding: 10px;
						width: auto;
					}
					@media (max-width: 440px) {
					}
				}

				&:nth-child(2) {
					width: 50%;
					@media (max-width: 850px) {
						padding: 10px;
						width: auto;
						display: table-cell;
					}
					@media (max-width: 440px) {
					}
				}

				&:nth-child(3) {
					width: 15%;
					@media (max-width: 850px) {
						padding: 10px;
						width: auto;
						display: block;

					}
					@media (max-width: 440px) {
					}
				}

				.service-name,
				.service-description {
					font-family: "Inter";
					font-weight: 300;
					font-size: 16px;
					line-height: 24px;
					color: rgba(255, 255, 255, 0.7);
					@media (max-width: 850px) {
						font-size: 16px;
					}
					@media (max-width: 440px) {
						font-size: 14px;
					}
				}
			}
		}
	}
}

.service-description {
	font-size: 14px; /* Размер шрифта */
	line-height: 1.5; /* Межстрочный интервал */
	color: #e0e0e0; /* Цвет текста */
	// padding: 10px 15px; /* Внутренние отступы */
	border-radius: 5px; /* Скругление углов */
	margin-bottom: 10px;

	p {
		margin-bottom: 10px; /* Отступ снизу у абзацев */
	}

	ul {
		list-style-type: disc; /* Маркеры для списка */
		padding-left: 20px; /* Отступ слева для списка */
		margin: 10px 0; /* Отступы сверху и снизу списка */
	}

	li {
		margin-bottom: 5px; /* Отступ снизу у элементов списка */
	}
}

.details-link {
	font-family: "Inter";
	font-weight: 300;
	font-size: 12px;
	line-height: 18px;
	color: #fdd85a;

	cursor: pointer;
	background: none;
	border: none;
	padding: 0;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.price-column {
	text-align: center !important;
	font-family: "Inter";
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	color: rgba($color: #fff, $alpha: 0.7);
}
