.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease;
  &.open {
    opacity: 1;
    pointer-events: all;
  }
  &.close {
    opacity: 0;
    pointer-events: none;
  }
}

.modal-content {
  background-color: #272726;
  border-radius: 20px;
  padding: 40px;
  position: relative;
  max-width: 500px;
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
    }
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }
  .formModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .formModal__inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      input,
      .react-tel-input .form-control {
        width: 100%;
        height: 100%;
        border-radius: 60px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        padding: 12px 25px;
        background-color: #272726;
        color: rgba(255, 255, 255, 0.7);
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        margin-bottom: 15px;
          &:nth-child(2) {
              padding-left: 75px;
          }
        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }
        &:focus,
        &:active {
          border: 1px solid rgba(255, 255, 255, 0.7);
        }

        // Стили автозаполнения
        &:autofill,
        &:autofill:hover,
        &:autofill:focus {
          border: 1px solid rgba(255, 255, 255, 0.7);
          -webkit-text-fill-color: rgba(255, 255, 255, 0.7);
          -webkit-box-shadow: 0 0 0px 1000px #272726 inset;
          transition: background-color 5000s ease-in-out 0s;
        }
      }

      // Красная обводка для инпутов с ошибкой
      .input-error {
        border: 1px solid red !important;
        border-radius: 60px;
      }

      .react-tel-input .flag-dropdown {
        background-color: #272726;
        margin: 1px;
        border-radius: 60px 0 0 60px;
      }

      .react-tel-input .selected-flag {
        background-color: #272726;
        border-radius: 60px 0 0 60px;
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
      }

      // Стрелочка с использованием изображения
      .react-tel-input .arrow {
        width: 12px;
        height: 12px;
        background-image: url("./images/arrow.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 10px;
        transition: transform 0.3s ease;
        top: -3px;
      }

      .react-tel-input .flag-dropdown.open .arrow {
        transform: rotate(180deg);
      }
    }
    button {
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.success,
.error {
  text-align: center;
  margin-top: 20px;
  h2 {
    color: #fdd551;
    margin-bottom: 10px;
  }
  p {
    color: #fff;
  }
}

.reset-button-styles {
  all: unset;
  display: inline-block;
}

@media (max-width: 500px) {
  .modal-content {

    padding: 40px 20px;
    h2 {
      font-size: 20px;
    }
    .formModal {
      .formModal__inner {
        input,
        .react-tel-input .form-control {
          font-size: 14px;
        }
        button {
          font-size: 14px;
        }
      }
    }
  }
}
