*,
*::before,
*::after {
    box-sizing: border-box;
}
/* Убираем внутренние отступы */

ul[class],
ol[class] {
    padding: 0;
    margin: 0;
}


/* Убираем внешние отступы */

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    padding: 0;
    margin: 0;
}


/* Выставляем основные настройки по-умолчанию для body */

body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    font-size: 14px;
}


/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/

ul[class],
ol[class] {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
}


/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */

a:not([class]) {
    text-decoration-skip-ink: none;
    text-decoration: none;
}

a {
    text-decoration-skip-ink: none;
    text-decoration: none;
    color: inherit;
}


/* Упрощаем работу с изображениями */

img {
    max-width: 100%;
    display: block;
}


/* Указываем понятную периодичность в потоке данных у article*/

article>*+* {
    margin-top: 1em;
}


/* Наследуем шрифты для инпутов и кнопок */

input,
button,
textarea,
select {
    margin: 0;
    padding: 0;
    outline: none;
}

a {
	text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}